import React from "react"

export default function BasicLayout({ left, right }) {

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '2rem' }} >
            <div style={{ flex: '6 1 700px', padding: '0 1rem' }}>
                {left}
            </div>
            <div style={{ flex: '1 1 300px', padding: '0 1rem', marginTop: '1rem' }}>
                {right}
            </div>
        </div>
    )
}